import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbItemComponent } from './breadcrumb-item.component';

import { BreadcrumbCollection } from './breadcrumb.collection';
import {RouterModule} from "@angular/router";

const PUBLIC_API = [BreadcrumbComponent, BreadcrumbItemComponent];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [CommonModule, RouterModule],
  exports: [...PUBLIC_API],
})
export class BreadcrumbModule {
  public static forRoot(): ModuleWithProviders<BreadcrumbModule> {
    return {
      ngModule: BreadcrumbModule,
      providers: [BreadcrumbCollection],
    };
  }
}
