<div>
  <div class="flexic mb-4">
    <h3 translate class="mb-0">candidate::panel.wizard.title.education</h3>
    <ng-container *ngIf="this.isLoading">
      <button class="ml-3" type="button" a3l-ui-button icon small color="gray-revert" disabled>
        <a3l-ui-spinner color="gray" size="25"></a3l-ui-spinner>
      </button>
    </ng-container>
    <ng-container *ngIf="!this.isLoading">
      <button *ngIf="!this.value.length" class="ml-3" type="button" a3l-ui-button icon small color="gray-revert" (click)="addNew();">
        <a3l-ui-icon icon="plus" class="text-xs"></a3l-ui-icon>
      </button>
      <button *ngIf="this.value.length" class="ml-3" type="button" a3l-ui-button icon small color="gray-revert" disabled>
        <span class="text-xs">{{ this.value.length}}</span>
      </button>
    </ng-container>
  </div>
  <ng-container>
    <p-accordion [multiple]="true" [activeIndex]="currentIndex" #accordion>
      <p-accordionTab *ngFor="let item of value; index as i; trackBy:identify; let last = last">
        <ng-template pTemplate="header">
          <div class="flex justify-between w-full pr-3">
            <div class="flexic">
              <a3l-ui-icon [class]="'text-xxs text-gray-dark'" [icon]="'chevron-right'" *ngIf="currentIndex.indexOf(i) != -1"></a3l-ui-icon>
              <a3l-ui-icon [class]="'text-xxs text-gray-dark'" [icon]="'chevron-down'" *ngIf="!(currentIndex.indexOf(i) != -1)"></a3l-ui-icon>
              <ng-container *ngIf="!(currentIndex.indexOf(i) != -1)">
                <div class="ml-5 mr-3">
                  <strong class="mt-0 hover:none">{{ item.controls['field'].value }}</strong>
                  <p class="m-0 p-0" *ngIf="item.controls['name'].value">{{ item.controls['name'].value }}</p>
                </div>
              </ng-container>
            </div>
            <div>
              <p class="whitespace-nowrap">
                <ng-container *ngIf="!(currentIndex.indexOf(i) != -1) && item.controls['start_date'].value">
                  <span class="mt-0 mr-5 text-gray-dark text-sm font-medium">{{ item.controls['start_date'].value + ' - '}}{{item.controls['end_date'].value | translate}}</span>
                </ng-container>
                <button type="button" a3l-ui-button icon small plain color="gray" (click)="removePosition(item.controls['uuid'].value)">
                  <a3l-ui-icon icon="trash" class="text-xxs text-gray-dark"></a3l-ui-icon>
                </button>
              </p>
            </div>
          </div>
        </ng-template>
        <rex-education-item [form]="item" (itemChangedEvent)="update($event)"></rex-education-item>
      </p-accordionTab>
    </p-accordion>

    <a *ngIf="value.length >= 1" (click)="addNew()" type="button" class="text-xs pl-3 align-middle" a3l-ui-button small color="default" translate>
      <a3l-ui-icon icon="plus" class="mr-2 text-xxxs"></a3l-ui-icon>
      {{ 'candidate::panel.wizard.navigate.add_new_education_position' | translate }}
    </a>
  </ng-container>
</div>
