import { Injectable, NgZone } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class Dispatcher {
  /**
   * Create a new instance.
   *
   * @var {Subject}
   */
  private stream: Subject<any> = new Subject<any>();

  /**
   * Create a new instance.
   *
   * @param {NgZone} ngZone
   */
  constructor(private ngZone: NgZone) {
    //
  }

  /**
   * Push the event to the bus.
   *
   * @param {any} event
   * @return void
   */
  emit(event: any): void {
    this.stream.next(event);
  }

  /**
   * Subscribe to the bus.
   *
   * @param {any[]} events
   * @return Observable<any>
   */
  listen(...events: any[]): Observable<any> {
    return this.stream.pipe(
      filter((item: any) => {
        for (let i = 0, x = events.length; i < x; i++) {
          const event = events[i];

          if (item == event || item.type == event.type || (event instanceof Function && item instanceof event)) {
            return true;
          }
        }

        return false;
      })
    );
  }

  /**
   * Subscribe to the bus.
   *
   * @param {any[]} args
   * @return Subscription
   */
  on(...args: any[]): Subscription {
    const [callback, ...events] = args.reverse();

    return this.listen(...events).subscribe((event) => this.ngZone.run(() => callback(event)));
  }
}
