import { Injectable } from '@angular/core';
import {finalize, map} from 'rxjs/operators';

import { Query } from '@a3l/core';
import { ApiService } from '@a3l/api';

@Injectable({ providedIn: 'root' })
export class DictionaryArrayOfIndustriesQuery extends Query<any> {
  /**
   * Create a new instance.
   *
   * @param {ApiService} service
   */
  constructor(private api: ApiService) {
    super();
  }

  /**
   * Run the query.
   *
   * @return void
   */
  execute(): void {
    this._pending$.next(true);

    this.api
      .get('/dictionaries/industries' , { as_content_array: true })
      .pipe(
        finalize(() => {
          this._pending$.next(false);
        })
      )
      .subscribe((values) => {
        this._pending$.next(false);

        this._value$.next(values);
      });
  }
}
