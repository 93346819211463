import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from './core';
import { MenuModule } from './menu';
import { TabsModule } from './tabs';
import { FormModule } from './form';
import { FlagModule } from './flag';
import { AffixModule } from './affix';
import { BadgeModule } from './badge';
import { DialogModule } from './dialog';
import { DropdownModule } from './dropdown';
import { SegmentModule } from './segment';
import { SpinnerModule } from './spinner';
import { SnackBarModule } from './snack-bar';
import { ProgressBarModule } from './progress-bar';
import { PortalModule } from './portal';
import { WysiwygModule } from './wysiwyg';
import { TooltipModule } from './tooltip';
import { PopoverModule } from './popover';
import { ScrollbarModule } from './scrollbar';
import { ModalModule } from "./modal";
import {NumbersOnlyDirective} from "./numbers-only.directive";
import {TokenModule} from "./token";
import {HelperModule} from "./helper";

const PUBLIC_API = [
  CoreModule,
  MenuModule,
  TabsModule,
  FormModule,
  FlagModule,
  AffixModule,
  BadgeModule,
  DialogModule,
  ModalModule,
  DropdownModule,
  SegmentModule,
  SnackBarModule,
  SpinnerModule,
  PortalModule,
  ProgressBarModule,
  WysiwygModule,
  TooltipModule,
  PopoverModule,
  HelperModule,
  ScrollbarModule,
  NumbersOnlyDirective,
  TokenModule
];

@NgModule({
  declarations: [NumbersOnlyDirective],
  imports: [CommonModule],
  exports: [...PUBLIC_API],
})
export class UtilitiesModule {
  public static forRoot(): ModuleWithProviders<UtilitiesModule> {
    return {
      ngModule: UtilitiesModule,
      providers: [],
    };
  }
}
