import {
  Component,
  EventEmitter,
  Input, OnInit,
  Output
} from "@angular/core";
import {LanguageSwitcherCommand} from "@rex/common/language.switcher.command";
import {TranslateService} from "@ngx-translate/core";
import {nextTick} from "@a3l/utilities";

@Component({
  selector: 'localized-language-switcher',
  templateUrl: './localized-language-switcher.component.html',
  styleUrls: ['./localized-language-switcher.component.scss'],
})
export class LocalizedLanguageSwitcherComponent implements OnInit {

  readonly languages = this.translator.langs;

  /**
   * @var {string}
   */
  @Input()
  code: string;


  @Input()
  disabled: boolean;

  @Output()
  languageChanged: EventEmitter<any> = new EventEmitter();

  /**
   * Create a new instance.
   *
   * @param {TranslateService} translator
   * @param {LanguageSwitcherCommand} command
   */
  constructor(private translator: TranslateService, private command: LanguageSwitcherCommand) {
  }

  ngOnInit() {
    if(this.code == null) {
      this.code = this.translator.currentLang;
      nextTick(() => this.languageChanged.emit(this.code));
    }
  }

  /**
   * Handle the action.
   *
   * @param {string} language
   * @return void
   */
  process(language: string): void {
    if (this.code == language) return;

    this.code = language;

    this.languageChanged.emit(this.code);
  }
}
