import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rex-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  host: {
    class: 'rex-searchbar',
    '[class.rex-searchbar--focused]': 'focused',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SearchbarComponent {
  /**
   * @var {string}
   */
  @Input()
  placeholder: string = '';

  /**
   * @var {boolean}
   */
  focused: boolean = false;

  /**
   * Create a new instance.
   */
  constructor() {
    //
  }
}
