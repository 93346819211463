import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay';

import { HelperComponent } from './helper.component';
import { HelperDirective } from './helper.directive';
import {HelperButton} from "./helper.button";
import {IconModule} from "@a3l/utilities";
import {TooltipModule} from "../tooltip";

const PUBLIC_API = [HelperComponent, HelperButton];

const PRIVATE_API = [HelperDirective];

@NgModule({
    declarations: [...PUBLIC_API, ...PRIVATE_API],
    imports: [CommonModule, OverlayModule, TooltipModule],
    exports: [...PUBLIC_API]
})
export class HelperModule {}
