import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {uuid} from "@a3l/utilities";

@Injectable({providedIn: 'root'})
export class EducationForm {
  public emptyForm = (params = null) => {
    return new FormGroup({
      uuid: new FormControl(uuid()),
      name: new FormControl(null, Validators.required),
      field: new FormControl(null, Validators.required),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
    });
  }

  public formFromArray = (array) => {
    let form = this.emptyForm(array.parameters);
    form.controls['uuid'].setValue(array.uuid ? array.uuid : uuid());
    form.controls['name'].setValue(array.name);
    form.controls['field'].setValue(array.field);
    form.controls['start_date'].setValue(array.start_date);
    form.controls['end_date'].setValue(array.end_date);

    return form;
  }

}
