import {
  AfterContentChecked,
  Component, EventEmitter,
  forwardRef,
  Input, OnChanges, SimpleChanges
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from './../control';
import {LocalizedContentFieldCreator} from "./localized-content-field-creator";
import {LocalizedContent} from "./localized-content";
import {nextTick} from "@a3l/utilities";

@Component({
  selector: 'localized-wysiwyg-editor',
  templateUrl: './localized-wysiwyg-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizedWysiwygInputComponent),
      multi: true,
    },
    {provide: Control, useExisting: LocalizedWysiwygInputComponent},
  ],
  host: {
    class: 'localized-wysiwyg-editor',
    '(onFocus)': 'focused = true',
    '(onBlur)': 'focused = false',
  },
})
export class LocalizedWysiwygInputComponent extends Control implements ControlValueAccessor, OnChanges {

  /**
   * @var {any}
   */
  @Input()
  value: LocalizedContent;

  @Input()
  disabled = false;

  @Input()
  activeLanguage: string;

  shouldRefreshHtml: boolean = false;

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  contentChanged($event) {
    this.value.setContentForLang(this.activeLanguage, $event);
    this.propagateChange(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.activeLanguage && changes.activeLanguage.currentValue) {
      this.shouldRefreshHtml = true;
      nextTick(() => this.shouldRefreshHtml = false);
    }
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    let creator = new LocalizedContentFieldCreator(value);
    this.value = creator.getArray();
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {
  }
}
