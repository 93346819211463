import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Injectable} from "@angular/core";
import {uuid} from "@a3l/utilities";

@Injectable({providedIn: 'root'})
export class WorkExperienceForm {
  public emptyForm = (params = null) => {
    return new FormGroup({
      uuid: new FormControl(uuid()),
      company_name: new FormControl(null, Validators.required),
      position_name: new FormControl(null, Validators.required),
      from: new FormControl(null),
      to: new FormControl(null),
      responsibilities: new FormControl(null),
      industry: new FormControl(null)
    });
  }

  public formFromArray = (array) => {
    let form = this.emptyForm(array.parameters);
    form.controls['uuid'].setValue(array.uuid ? array.uuid : uuid());
    form.controls['company_name'].setValue(array.company_name);
    form.controls['position_name'].setValue(array.position_name);
    form.controls['from'].setValue(array.from);
    form.controls['to'].setValue(array.to);
    form.controls['responsibilities'].setValue(array.responsibilities);
    form.controls['industry'].setValue(array.industry);

    return form;
  }

}
