import { Directive, HostListener, Input, ElementRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { take } from 'rxjs/operators';

import { TooltipComponent } from './tooltip.component';

export const UI_TOOLTIP_PANEL_CLASS = 'cdk-a3l-ui-tooltip-pane';

@Directive({
  selector: '[a3l-ui-tooltip]',
})
export class TooltipDirective implements OnDestroy {
  /**
   * @var {string}
   */
  @Input('a3l-ui-tooltip')
  message: string;

  /**
   * @var {boolean}
   */
  @Input('a3l-ui-tooltip-disabled')
  disabled: boolean = false;

  /**
   * @var {OverlayRef}
   */
  private overlayRef: OverlayRef | null;

  /**
   * @var {TooltipComponent}
   */
  private tooltipComponentInstance: TooltipComponent | null;

  /**
   * Create a new instance.
   *
   * @param {Overlay} overlay
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private overlay: Overlay, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    //
  }

  /**
   * Listen for mouseover event.
   *
   * @return void
   */
  @HostListener('mouseover')
  show(): void {
    if (!this.message || this.disabled) return;

    if (this.tooltipComponentInstance) return;

    this.createTooltipComponent();
  }

  /**
   * Hide the tooltip after the delay in ms.
   *
   * @return void
   */
  @HostListener('mouseleave')
  hide(): void {
    if (!this.tooltipComponentInstance) return;

    this.tooltipComponentInstance.hide();
  }

  /**
   * Create the tooltip to display.
   *
   * @return void
   */
  private createTooltipComponent(): void {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }])
      .withPush(true);

    this.overlayRef = this.overlay.create({
      panelClass: UI_TOOLTIP_PANEL_CLASS,
      positionStrategy: strategy,
    });

    this.overlayRef.backdropClick().pipe(take(1)).subscribe(() => this.hide()); // prettier-ignore

    const portal = new ComponentPortal(TooltipComponent, this.viewContainerRef);

    this.tooltipComponentInstance = this.overlayRef.attach(portal).instance;

    this.tooltipComponentInstance.message = this.message;
    this.tooltipComponentInstance.markForCheck();
    this.tooltipComponentInstance.show();

    this.tooltipComponentInstance.afterHidden.subscribe(this.disposeTooltipComponent.bind(this));
  }

  /**
   * Remove the tooltip and the overlay.
   *
   * @return void
   */
  private disposeTooltipComponent(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    if (this.tooltipComponentInstance) {
      this.tooltipComponentInstance = null;
    }
  }

  /**
   * Dispose the tooltip when destroyed.
   */
  ngOnDestroy() {
    if (!this.tooltipComponentInstance) {
      return;
    }

    this.disposeTooltipComponent();
  }
}
