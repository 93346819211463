import {
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import {Control} from './control';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Overlay} from "@angular/cdk/overlay";
import {Subscription} from "rxjs";
import {CalendarService} from "../services/calendar.service";
import {nextTick} from "@a3l/utilities";

@Component({
  selector: 'rex-experience-date',
  templateUrl: './experience-date.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExperienceDateComponent),
      multi: true,
    },
    { provide: Control, useExisting: ExperienceDateComponent },
  ],
  host: {
    class: 'rex-experience-date',
    '(onFocus)': 'focused = true',
    '(onBlur)': 'focused = false',
  },
  styleUrls: ['experience-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExperienceDateComponent extends Control implements ControlValueAccessor {

  oldDate: any;

  /**
   * @var {any}
   */
  @Input()
  value: any;

  /**
   * @var {any}
   */
  @Input()
  showPresent: boolean = false;

  /**
   * @var {string}
   */
  @Input()
  placeholder: string;

  @ViewChild('picker') public picker;

  isPresent: boolean;

  subscription: Subscription;
  /**
   * Create a new instance.
   *
   * @param {CalendarService} calendarService
   * @param {Overlay} overlay
   * @param {Injector} injector
   * @param {ElementRef} elementRef
   * @param {ViewContainerRef} viewContainerRef
   */
  constructor(private calendarService: CalendarService, private overlay: Overlay, private injector: Injector, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {
    super();

    this.calendarService.setTranslation();
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
    this.setVisibleText(this.value);
  }

  /**
   * Write a new value from the form model.
   *
   * @param {Date} value
   * @return void
   */
  writeValue(value): void {
    this.setPresent(false, false);
    if(value == 'Do dziś') {
      this.setPresent(true, false);
    }

    this.value = value;
    this.setVisibleText(value);
    this.propagateChange(this.value)
  }

  change($event): void {
    let date = new Date($event);
    let month = date.getMonth() + 1;
    let formattedMonth;
    if(month < 10) {
      formattedMonth = '0' + month.toString();
    } else {
      formattedMonth = month.toString()
    }
    let value = formattedMonth + '/' + date.getFullYear().toString()
    this.writeValue(value);
    this.setVisibleText(value);
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  setPresent($event, fireEvent = true) {
    this.isPresent = $event;
    this.value = this.isPresent ? 'Do dziś' : null;
    if(fireEvent) {
      this.propagateChange(this.value);
    }
    if(this.isPresent) {
      this.setVisibleText(this.value);
    }
    if(this.picker) {
      this.picker.hideOverlay()
    }
  }

  setVisibleText(value)
  {
    nextTick(() => this.elementRef.nativeElement.getElementsByTagName('input')[0].value = value);
  }
}
