import {
  AfterContentChecked,
  Component,
  forwardRef, HostListener,
  Input, SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Control} from './../control';
import {QuillViewComponent} from "ngx-quill";
import {LocalizedContentFieldCreator} from "./localized-content-field-creator";
import {LocalizedContent} from "./localized-content";
import {TranslateService} from "@ngx-translate/core";
import {nextTick} from "@a3l/utilities";

@Component({
  selector: 'localized-input-editor',
  templateUrl: './localized-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocalizedInputComponent),
      multi: true,
    },
    {provide: Control, useExisting: LocalizedInputComponent},
  ],
  host: {
    class: 'localized-input-editor',
    '(focus)': 'focused = true',
    '(blur)': 'focused = false',
  },
})
export class LocalizedInputComponent extends Control implements ControlValueAccessor {

  /**
   * @var {any}
   */
  @Input()
  value: LocalizedContent;

  @Input()
  activeLanguage: string

  @Input()
  placeholder = 'Wpisz';

  /**
   * @var {boolean}
   */
  @Input()
  disabled: boolean = false;

  constructor(private translator: TranslateService) {
    super();
  }

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {
  };

  /**
   * Handle the 'keyup' event.
   *
   * @param {KeyboardEvent} event
   * @return void
   */
  @HostListener('keydown', ['$event'])
  protected onDOMKeyup(event: KeyboardEvent): void {
    this.contentChanged(event);
  }

  contentChanged($event) {
    this.value.setContentForLang(this.activeLanguage, $event.target.value);
    this.propagateChange(this.value);
  }

  /**
   * @var {boolean}
   */
  get canShowValidationMessage(): boolean {
    return this.focused;
  }

  /**
   * Focus on the input.
   *
   * @return void
   */
  focusin(): void {
    this.focused = true;
  }

  /**
   * Focusout of the input.
   *
   * @return void
   */
  focusout(): void {
    this.focused = false;
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    let creator = new LocalizedContentFieldCreator(value);
    this.value = creator.getArray();
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {
  }
}
