import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule as BaseCommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";

import { LayoutModule } from '@a3l/layout';
import { DatasetModule } from '@a3l/dataset';
import { UtilitiesModule } from '@a3l/utilities';
import { BreadcrumbModule } from '@a3l/breadcrumb';

import { PhoneField } from './phone.field';

import { AreaField } from './area.field';
import { LanguagesField } from './languages.field';
import { LanguagesItemFieldComponent } from './languages-item-field.component';
import { AgreementsField } from './agreements.field';
import { IndustriesMultiselectField } from "./industries-multiselect.field";

import { LogoComponent } from './logo.component';
import { AvatarComponent } from './avatar.component';

import { FilePresenter } from './file.presenter';

import { LanguageSwitcher } from './language.switcher';
import { IsLanguageDirective } from './is-language.directive';

import { SearchbarComponent } from './searchbar.component';

import { GoogleMapComponent } from './google-map.component';

import { CountryPipe } from './country.pipe';
import { CountriesPipe } from './countries.pipe';
import {FileDownloadDirective} from "@rex/common/file-download.directive";

import {QuillModule} from "ngx-quill";
import {ConsoleLogPipe} from "@rex/common/console-log.pipe";
import {CandidatesColumnComponent} from "@rex/core/recruiter/columns/candidates.component";
import {TraffitDisciplinesMultiselectField} from "@rex/common/traffit-disciplines-multiselect.field";
import {TextFieldModule} from "@angular/cdk/text-field";
import {UserIdComponent} from "@rex/common/user-id.component";
import {RatingModule} from "primeng/rating";
import {AuthModule} from "@rex/auth";
import {BarLoader} from "@rex/common/bar-loader";
import {WorkExperiencesField} from "./work-experiences/work-experiences.field";
import {WorkExperienceItem} from "@rex/common/work-experiences/work-experience-item";
import {EducationField} from "@rex/common/educations/education.field";
import {EducationItem} from "@rex/common/educations/education-item";
import {ClipboardDirective} from "@rex/common/clipboard.directive";
import {StarRatingComponent} from "@rex/common/star-rating.component";
import {LanguageChoiceRatingComponent} from "@rex/common/components/language-choice-rating.component";
import {AutoCompleteModule} from "primeng/autocomplete";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AutocompleteRatingComponent} from "../../../../../libs/utilities/src/lib/form/autocomplete-rating.component";
import {LocalizationSearcher} from "@rex/common/localization-searcher.directive";
import {LocalizationField} from "@rex/common/localization.field";
import {LocalizationWizardDialog} from "@rex/common/localization-wizard.dialog";
import {CvFilterComponent} from "@rex/common/filter-button/cv-filter.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {SingleLocalizationField} from "@rex/common/single-localization.field";
import {RecruiterFilterComponent} from "@rex/common/filter-button/recruiter-filter.component";
import {NgxSliderModule} from "@angular-slider/ngx-slider";
import {TextSpinnerComponent} from "@rex/common/components/text-spinner.component";
import {PasswordField} from "@rex/common/fields/password.field";
import {StrongPasswordHelper} from "@rex/common/fields/strong-password-helper";
import {JobOfferFilterComponent} from "@rex/common/filter-button/job-offer-filter.component";
import {AccordionModule} from "primeng/accordion";
import {SkillItem} from "@rex/common/skills/skill.item";
import {SoftSkillsField} from "@rex/common/skills/soft-skills.field";
import {HardSkillsField} from "@rex/common/skills/hard-skills.field";
import {IndustryItem} from "@rex/common/industries/industry.item";
import {IndustriesField} from "@rex/common/industries/industries.field";
import {LanguageSkillsField} from "@rex/common/language-skills/language-skills.field";
import {LanguageSkillItem} from "@rex/common/language-skills/language-skill-item";
import {RecruiterPlanComponent} from "@rex/common/recruiter-plan.component";
import {OrganizationsMultiselectField} from "@rex/common/organizations-multiselect.field";
import {LocalizedModule} from "../../../../../libs/utilities/src/lib/form/localized-content/localized.module";
import { IndustryField } from '@rex/common/industries/industry.field';

export const A3L_API = [LayoutModule, DatasetModule, UtilitiesModule, BreadcrumbModule, TextFieldModule];

export const PUBLIC_API = [
  PhoneField,
  AreaField,
  LanguagesField,
  WorkExperiencesField,
  WorkExperienceItem,
  EducationField,
  EducationItem,
  LanguagesItemFieldComponent,
  IndustriesMultiselectField,
  AgreementsField,
  LogoComponent,
  FilePresenter,
  LanguageSwitcher,
  IsLanguageDirective,
  AvatarComponent,
  RecruiterPlanComponent,
  SearchbarComponent,
  GoogleMapComponent,
  CountryPipe,
  CountriesPipe,
  FileDownloadDirective,
  ClipboardDirective,
  ConsoleLogPipe,
  CandidatesColumnComponent,
  TraffitDisciplinesMultiselectField,
  UserIdComponent,
  BarLoader,
  StarRatingComponent,
  LanguageChoiceRatingComponent,
  LocalizationField,
  AutocompleteRatingComponent,
  LocalizationSearcher,
  LocalizationWizardDialog,
  CvFilterComponent,
  RecruiterFilterComponent,
  SingleLocalizationField,
  TextSpinnerComponent,
  PasswordField,
  StrongPasswordHelper,
  JobOfferFilterComponent,
  SkillItem,
  SoftSkillsField,
  HardSkillsField,
  IndustryItem,
  IndustriesField,
  IndustryField,
  LanguageSkillsField,
  LanguageSkillItem,
  OrganizationsMultiselectField
];

@NgModule({
  declarations: [...PUBLIC_API],
  imports: [
    BaseCommonModule, FormsModule, RouterModule, ReactiveFormsModule, TranslateModule, RatingModule, NgMultiSelectDropDownModule.forRoot(), OverlayPanelModule, OverlayModule, NgxMaskModule.forRoot({
      validation: false,
      dropSpecialCharacters: false
    }),
    ...A3L_API, AuthModule, AutoCompleteModule, OverlayPanelModule, NgxSliderModule, QuillModule, AccordionModule, LocalizedModule,
  ],
  exports: [FormsModule, RouterModule, ReactiveFormsModule, TranslateModule, BaseCommonModule, NgxMaskModule, QuillModule, RatingModule, ...A3L_API, ...PUBLIC_API],
})
export class CommonModule {}
