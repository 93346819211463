<ng-container *ngIf="items$ | async as items">
  <ul class="a3l-breadcrumb-list">
    <li class="a3l-breadcrumb-list__item a3l-breadcrumb__go-back" (click)="goBack()">
      <svg width="11px" height="6px" viewBox="0 0 11 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="[VD]-On-boarding" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="on-boarding--step-2-2" transform="translate(-600.000000, -1649.000000)" fill="#000000" fill-rule="nonzero">
            <g id="main" transform="translate(448.000000, 189.000000)">
              <g id="btn" transform="translate(152.000000, 1454.000000)">
                <path
                  d="M2.98917749,12 C3.22753423,12 3.44133902,11.9064604 3.60247114,11.7453283 C3.75895682,11.5888426 3.85714286,11.3784439 3.85714286,11.1536797 L3.84914535,11.0426777 C3.82279474,10.8602801 3.73287587,10.6924358 3.60247114,10.562031 L2.8979697,9.858 L9.85064935,9.85714286 C10.3215969,9.85714286 10.6969697,9.48177008 10.6969697,9.01082251 L10.6904336,8.90396625 C10.6386545,8.48434642 10.2853702,8.16450216 9.85064935,8.16450216 L2.8979697,8.165 L3.60247114,7.459614 C3.9232576,7.08762615 3.9232576,6.57545817 3.60247114,6.25467172 C3.37511309,6.07787162 3.19712083,6 2.98917749,6 L2.88739117,6.00464338 C2.68788165,6.02342694 2.51415648,6.10045181 2.39151731,6.2584051 L2.3449697,6.33 L0.254671718,8.41917388 C0.09818604,8.57565956 1.16351373e-13,8.78605822 1.16351373e-13,9.01082251 C1.16351373e-13,9.2355868 0.09818604,9.44598546 0.254671718,9.60247114 L2.39752886,11.7453283 C2.55401454,11.901814 2.7644132,12 2.98917749,12 Z"
                  id="arrow-right"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </li>
    <ng-container *ngFor="let item of items; let first = first; let last = last">
      <li class="a3l-breadcrumb-list__item a3l-breadcrumb-list__item__regular" [ngClass]="{ 'a3l-breadcrumb-item--locked': !item.link }">
        <a *ngIf="item.link" [routerLink]="item.link">
          <span [innerHTML]="item.title" *ngIf="item.title"></span>
          <ng-container [ngTemplateOutlet]="item.templateRef" *ngIf="item.templateRef"></ng-container>
        </a>
        <span *ngIf="!item.link">
          <span [innerHTML]="item.title" *ngIf="item.title"></span>
          <ng-container [ngTemplateOutlet]="item.templateRef" *ngIf="item.templateRef"></ng-container>
        </span>
      </li>
    </ng-container>
  </ul>
  <ng-content></ng-content>
</ng-container>
