<ng-container [ngSwitch]="icon">
  <ng-container *ngSwitchCase="'google'">
    <img src="/assets/icons/google.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'linkedin'">
    <img src="/assets/icons/linkedin.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'facebook'">
    <img src="/assets/icons/facebook.svg" alt="Icon" />
  </ng-container>
  <ng-container *ngSwitchCase="'like'">
    <img src="/assets/icons/like.svg" alt="Icon" />
  </ng-container>
</ng-container>
