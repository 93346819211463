import {
  Component,
  AfterContentInit,
  ViewChild,
  ViewChildren,
  ContentChildren,
  QueryList,
  ElementRef,
  ViewEncapsulation,
  OnInit, Input
} from '@angular/core';

import { nextTick } from '../utils';

import { TabComponent } from './tab.component';
import { TabsBarComponent } from './tabs-bar.component';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'a3l-ui-quick-tab',
  templateUrl: './quick-tab.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class QuickTabComponent {
 @Input() element
 @Input() title
 @Input() first = false
}
