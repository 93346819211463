import { Component, Input, OnInit, OnDestroy, AfterContentInit, TemplateRef, ContentChild } from '@angular/core';
import { Router } from '@angular/router';

import { BreadcrumbCollection } from './breadcrumb.collection';

@Component({
  selector: 'a3l-breadcrumb-item',
  template: '<ng-content></ng-content>',
})
export class BreadcrumbItemComponent implements OnInit, AfterContentInit, OnDestroy {
  /**
   * @var {string}
   */
  @Input()
  get title(): string {
    return this._title;
  }
  set title(title: string) {
    this._title = title;

    this.collection.update(this.id, { title });
  }

  /**
   * @var {any}
   */
  @Input()
  routerLink: any;

  /**
   * @var {boolean}
   */
  @Input('omit')
  get omittable(): boolean {
    return this._omittable;
  }
  set omittable(value: boolean) {
    this._omittable = value !== false;
  }

  /**
   * @var {boolean}
   */
  @Input()
  get persistable(): boolean {
    return this._persistable;
  }
  set persistable(value: boolean) {
    this._persistable = value !== false;
  }

  /**
   * @var {TemplateRef}
   */
  @ContentChild(TemplateRef, { static: false })
  templateRef: TemplateRef<any>;

  /**
   * @var {number}
   */
  protected id: number;

  /**
   * @var {string}
   */
  protected _title: string;

  /**
   * @var {boolean}
   */
  protected _omittable: boolean = false;

  /**
   * @var {boolean}
   */
  protected _persistable: boolean = false;

  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {BreadcrumbCollection} collection
   */
  constructor(private router: Router, private collection: BreadcrumbCollection) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.id = this.collection.add(this.title, this.routerLink, this.persistable, this.omittable);
  }

  /**
   * Initialization.
   */
  ngAfterContentInit() {
    if (!this.templateRef) return;

    this.collection.update(this.id, { templateRef: this.templateRef });
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    this.collection.remove(this.id);
  }
}
