<a3l-ui-dialog-header>
  <h2>Konfiguracja Blind CV</h2>
</a3l-ui-dialog-header>
<a3l-ui-dialog-content>
  <form [formGroup]="form">
    <a3l-ui-form-group class="mb-2" plain>
      <a3l-ui-checkbox [scale]="0.8"  formControlName="show_company_data">
        <span class="text-md" translate>cv::blind.config.show_company_data</span>
      </a3l-ui-checkbox>
    </a3l-ui-form-group>
    <a3l-ui-form-group class="mb-2" plain>
      <a3l-ui-checkbox [scale]="0.8"  formControlName="show_availability_data">
        <span class="text-md" translate>cv::blind.config.show_availability_data</span>
      </a3l-ui-checkbox>
    </a3l-ui-form-group>
    <a3l-ui-form-group class="mb-2" plain>
      <a3l-ui-checkbox [scale]="0.8"  formControlName="show_financial_expectations">
        <span class="text-md" translate>cv::blind.config.show_financial_expectations</span>
      </a3l-ui-checkbox>
    </a3l-ui-form-group>
  </form>
</a3l-ui-dialog-content>
<a3l-ui-dialog-footer class="text-center">
  <button class="w-full" type="button" a3l-ui-button color="primary" (click)="close()" translate>Generuj</button>
</a3l-ui-dialog-footer>
