import {
  AfterViewInit,
  Component,
  forwardRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Control} from '@a3l/utilities';
import {Dispatcher} from "@a3l/core";
import {EducationForm} from "@rex/common/educations/education.form";
import {CvParserCandidateEducationEvent} from "@rex/shared/recruitments/events/cv-parser-candidate-education.event";

@Component({
  selector: 'rex-education-field',
  templateUrl: './education.field.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EducationField),
      multi: true,
    },
    { provide: Control, useExisting: EducationField },
  ],
  styleUrls: ['./education.field.scss', '../form-accordion.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EducationField extends Control implements OnInit, OnDestroy, AfterViewInit,  ControlValueAccessor {
  currentIndex = [];
  isValid = true;
  isLoading = false;

  @ViewChild('accordion') accordion;

  /**
   * @var {any}
   */
  value: any = [];

  /**
   * @var {any}
   */
  protected propagateChange: any = () => {};

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = Subscription.EMPTY;

  constructor(
    private dispatcher: Dispatcher,
    private educationItemForm: EducationForm
  ) {
    super();
  }

  addNew() {
    if (this.value.length) this.validate();

    if (!this.isValid) return;

    this.value.push(this.educationItemForm.emptyForm());
    this.propagateChange(this.value);
    this.currentIndex.push(this.value.length - 1);
  }

  validate()
  {
    if (!this.value.length) {
      this.isValid = true;
      return;
    }

    this.value.forEach((formGroup: FormGroup, index) => {
      this.isValid = formGroup.validate();

      if (!this.isValid && !this.currentIndex.includes(index)) {
        this.currentIndex.push(index);

        if (this.accordion) {
          this.accordion.updateSelectionState();
        }
      }

      if (!this.isValid) return;
    });
  }

  removePosition(uuid) {
    this.value.splice(this.getItemIndexByUuid(uuid), 1);
    this.propagateChange(this.value)

    this.validate();
  }

  getItemIndexByUuid(uuid) {
    let foundIndex = undefined;
    this.value.forEach((item, index) => {
      if(item.controls['uuid'].value == uuid) {
        foundIndex = index;
      }
    });

    return foundIndex;
  }

  update($event) {
    this.value[this.getItemIndexByUuid($event.controls['uuid'].value)] = $event;
    this.propagateChange(this.value)
  }

  identify(index, item)
  {
    return item.controls['uuid'].value;
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.dispatcher.listen(CvParserCandidateEducationEvent).subscribe(({value, disabled}) => {
      if(value === null) {
        this.value = [];
      } else if(value) {
        value.forEach((education) => {
          this.value.push(this.educationItemForm.formFromArray(education));
        });
      }
      this.propagateChange(this.value);
      disabled ? this.isLoading = true : this.isLoading = false;
    });
  }

  ngAfterViewInit()
  {
    this.accordion.activeIndexChange.subscribe( (index) => this.currentIndex = index);
  }

  /**
   * Write a new value from the form model.
   *
   * @param {any} value
   * @return void
   */
  writeValue(value: any): void {
    this.value = value;

    if (!this.value) return;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Register handler.
   *
   * @param {any} fn
   * @return void
   */
  registerOnTouched(fn: any): void {}

  /**
   * Initialization.
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
