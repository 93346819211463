import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'rex-education-item',
  templateUrl: './education-item.html',

})
export class EducationItem implements OnInit {
  @Input() form: FormGroup;
  @Output() itemChangedEvent = new EventEmitter<FormGroup>();

  ngOnInit() {
    this.form.valueChanges.subscribe((values) => {
      this.itemChangedEvent.emit(this.form);
    })
  }
}
