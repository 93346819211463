import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { WebCacheDB } from '@a3l/core';

import { BreadcrumbItem } from './breadcrumb-item';
import { BreadcrumbCollection } from './breadcrumb.collection';

@Component({
  selector: 'a3l-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  host: {
    class: 'a3l-breadcrumb',
  },
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  /**
   * @var {BreadcrumbItem[]}
   */
  readonly items$: Observable<BreadcrumbItem[]> = this.collection.all();

  /**
   * @var {any[]}
   */
  protected history: any[] = WebCacheDB.get('breacrumb', []);

  /**
   * @var {string}
   */
  protected previous: string = null;

  /**
   * @var {Subscription}
   */
  protected subscription: Subscription = new Subscription();

  /**
   * Create a new instance.
   *
   * @param {Router} router
   * @param {BreadcrumbService} collection
   */
  constructor(private router: Router, private collection: BreadcrumbCollection) {
    //
  }

  /**
   * Initialization.
   */
  ngOnInit() {
    this.previous = this.router.url;

    const whenItemsChange = this.items$.subscribe(() => {
      const { omittable = false } = this.collection.findBy('link', this.previous) || {};

      this.previous = omittable ? null : this.previous;
    });

    const whenRouterChange = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.previous != null && this.previous.split('#').length == 1) {
        this.history.push(this.previous);

        WebCacheDB.put('breacrumb', this.history);
      }

      this.previous = event.url;
    });

    this.subscription.add(whenItemsChange);

    this.subscription.add(whenRouterChange);
  }

  /**
   * Go back in the navigation.
   *
   * @return void
   */
  goBack(): void {
    const target = this.history.pop();

    WebCacheDB.put('breacrumb', this.history);

    if (target) {
      this.previous = null;

      this.router.navigateByUrl(target);

      return;
    }

    let index = this.collection.count() - 1, item = this.collection.getAt(index); // prettier-ignore

    while (item && item.link == this.previous) {
      item = this.collection.getAt(--index);
    }

    if (item == null) return;

    this.previous = null;

    this.router.navigate(Array.isArray(item.link) ? item.link : [item.link]);
  }

  /**
   * Cleanup.
   */
  ngOnDestroy() {
    WebCacheDB.forget('breadcrumb');

    this.subscription.unsubscribe();
  }
}
